import * as React from "react"
import Section from "../section/Section";
import {Divider, Header, Icon, Image, List, Segment} from "semantic-ui-react";
import SubSection from "../section/SubSection";
import Publications from "./Publications";

type Props = {}
const RelatedWork: React.FunctionComponent<Props> = (props) => {
    return (<>
            <Section
                name={"relatedWork"}
                header={<>
                    <Icon name='circle outline'/>
                    <Header.Content>Related Work</Header.Content>
                </>

                }
            >

                <>
                    {/*publications*/}
                    <SubSection
                        name="publications"
                        alwaysContent={<>

                            <Header as='h3' icon textAlign='center'>
                                <Icon name='paperclip'/>
                                <Header.Content>
                                    Publications & Conference Proceedings
                                </Header.Content>
                            </Header>
                        </>}
                        sometimesContent={<>
                            <Publications/>
                        </>}
                    />
                    <Divider/>
                    {/*invited talks*/}
                    <SubSection
                        name="invitedTalks"
                        alwaysContent={<>
                            <Header as='h3' icon textAlign='center'>
                                <Header.Content>
                                    Invited Talks and Tutorials
                                </Header.Content>
                            </Header>
                            <Header as='h5'>
                                <Header.Content>
                                    MAE 502 ‐ Research Methods in Mechanical and Aerospace Engineering
                                </Header.Content>
                                <Header.Subheader>
                                    Guest Lecturer ‐ Spring 2023 ‐ University at Buffalo
                                </Header.Subheader>
                            </Header>
                            <p>
                                Developed and presented lectures covering basics in modern software development
                                methodology, programming, and visualization/plotting.
                            </p>

                            <Header as='h5'>
                                <Header.Content>
                                    Coding ABLATE Self‐Guided Tutorial
                                </Header.Content>
                                <Header.Subheader>
                                    2022 ‐ University at Buffalo ‐ CHREST
                                </Header.Subheader>
                            </Header>
                            <p>
                                Developed online course designed to prepare new developers for contributing to ABLATE
                                and include git/version control, IDE/debugging, c/c++, PETSc, and ABLATE. The course is
                                composed from multiple resources including book chapters, online tutorials, videos,
                                manuals, and custom content.
                            </p>

                            <Header as='h5'>
                                <Header.Content>
                                    CCR’s Learning Lab Seminar Series
                                </Header.Content>
                                <Header.Subheader>
                                    Invited Speaker ‐ December 8, 2021 ‐ Buffalo, NY
                                </Header.Subheader>
                            </Header>
                            <p>
                                Provided overview of the Center for Hybrid Rocket Exascale Simulation Technology
                                framework development and methodology.
                            </p>

                            <Header as='h5'>
                                <Header.Content>
                                    Community Animal Welfare Society Foster/Volunteer Orientation
                                </Header.Content>
                                <Header.Subheader>
                                    Developer and Author ‐ 2019/2020 ‐ Salt Lake City, Utah
                                </Header.Subheader>
                            </Header>
                            <p>
                                Developed the framework and contributed to the content for a volunteer and foster based
                                online tutorial/course series. Courses included text and video content customized for
                                each volunteer based upon their roles.
                            </p>
                        </>}
                    />
                    <Divider/>
                    {/*training*/}
                    <SubSection
                        name="training"
                        alwaysContent={<>

                            <Header as='h3' icon textAlign='center'>
                                <Header.Content>
                                    Training & Professional Activities
                                </Header.Content>
                            </Header>
                            <Header as='h5'>
                                <Header.Content>
                                    Argonne Training Program on Extreme-Scale Computing (ATPESC)
                                </Header.Content>
                                <Header.Subheader>
                                    Activities July 30 – August 11, 2017 in St. Charles, IL
                                </Header.Subheader>
                            </Header>
                            <p>
                                Two-week training on the key skills, approaches, and tools to design, implement, and
                                execute computational science and engineering applications on current high-end computing
                                systems and the leadership-class computing systems of the future.
                            </p>


                        </>}

                    />
                </>

            </Section>
        </>


    );
}


export default RelatedWork;