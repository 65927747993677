import * as React from "react"
import {List, Image, Popup, Card, Icon, Modal, Button, Grid} from "semantic-ui-react";
import FosterItem from "./FosterItem";
const fosters = require('../../assets/fosters.json');

type Props = {
    imgSize:number;
    popUp:boolean;
}

const FosterList: React.FunctionComponent<Props> = (props) => {
    //As list
    const fosterList = fosters;

    return (
        <Grid centered>
            {
                fosterList.map( (item:any) => {
                    return (<FosterItem popUp={props.popUp} imgSize={props.imgSize}  key={item.ID} aniInfo={item}/>);
                })
            }
        </Grid>
    );
}






export default FosterList;