import React from 'react'
import {Dropdown, Icon, Label} from 'semantic-ui-react'

interface MenuProps {
    inverted:boolean;
}


const Menu = (props:MenuProps) => (
    <Dropdown
        icon={<Icon name='bars' size='large' inverted={props.inverted}/>}
        style={{
            position: "fixed",
            left: "10px",
            top: "10px",
            zIndex:100000
        }}

    >
        <Dropdown.Menu>
            <Dropdown.Item content='About' icon='user' href='#about' />
            <Dropdown.Item content='Current Project' icon='file code' href='#currentProject' />

            <Dropdown.Divider />
            <Dropdown.Header icon='globe' content='NonProfit Leadership' href='#nonprofitLeadership'/>
            <Dropdown.Item content='CAWS - Community Animal Welfare Society' href='#caws'/>
            <Dropdown.Item content='Friends of Ellicott Island' href='#friendsOfEllicott'/>
            <Dropdown.Divider />
            <Dropdown.Item icon='paw' content='Fostering & Volunteering' href='#fostering'/>
            <Dropdown.Divider />
            <Dropdown.Header icon='cog' content='Experience' href='#experience'/>
            <Dropdown.Item content='Ansys' href='#ansys'/>
            <Dropdown.Item content='Reaction Engineering International' href='#rei'/>
            <Dropdown.Item content='Computational Energy Transport Laboratory' href='#ub'/>
            <Dropdown.Item content='University at Buffalo' href='#ta'/>
            <Dropdown.Item content='PlugPower' href='#plugPower'/>
            <Dropdown.Divider />
            <Dropdown.Item text='Education' icon='university' href='#education' />
            <Dropdown.Item text='Related Work' href='#relatedWork' />
            <Dropdown.Divider />
            <Dropdown.Item content={
                <>
                    <Label as='a' href='mailto:matt@mcgurn.dev'>
                        <Icon name='mail' />
                        Matt@McGurn.dev
                    </Label>
                    <Label as='a' href='mailto:matt@rescueden.org'>
                        <Icon name='mail' />
                        Matt@rescueden.org
                    </Label>

                </>

            }
            />

        </Dropdown.Menu>
    </Dropdown>
)

export default Menu