import * as React from "react"
import Section from "../section/Section";
import {Header, Icon, List,Image} from "semantic-ui-react";

type Props = {
}
const Education: React.FunctionComponent<Props> = (props) => {
    return (
        <Section
            name="education"
            header={
            <>
                <Icon name='university'/>
                <Header.Content >Education</Header.Content>
            </>
        }>
            <List  relaxed>
                <List.Item>
                    <List.Content>
                        <List.Header>Doctor of Philosophy in Mechanical Engineering, February 2013</List.Header>
                        <List.Description>
                            Dissertation: <a href="http://hdl.handle.net/10477/50688"  target="_blank">“Numerical Modeling and Simulation of Flame Spread Over Charring Materials”</a><br/>
                            University at Buffalo, the State University of New York<br/>
                            GPA: 3.933/4.0; Advisor: Dr. Paul E. DesJardin<br/>
                        </List.Description>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Content>
                        <List.Header>Master of Science in Mechanical Engineering, June 2010</List.Header>
                        <List.Description>
                            Project: “A Parallel Computing Domain Decomposition Approach for Finite Element Models”<br/>
                            University at Buffalo, the State University of New York<br/>
                            Specialization in Fluid and Thermal Sciences, GPA: 3.91/4.0<br/>
                        </List.Description>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Content>
                        <List.Header>Bachelor of Science in Mechanical and Aerospace Engineering, June 2007</List.Header>
                        <List.Description>
                            Bachelor of Science in Mechanical and Aerospace Engineering, June 2007<br/>
                            University at Buffalo, the State University of New York<br/>
                            Graduated Magna Cum Laude, GPA: 3.70/4.0<br/>
                        </List.Description>
                    </List.Content>
                </List.Item>
            </List>
        </Section>


    );
}


export default Education;