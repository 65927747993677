import * as React from "react"
import Section from "../section/Section";
import {Divider, Header, Icon, Image, List} from "semantic-ui-react";
import SubSection from "../section/SubSection";
import chrestLogo from "../../assets/chrestLogo.svg";

type Props = {}
const Experience: React.FunctionComponent<Props> = (props) => {
    return (<Section
            name={"experience"}
            header={<>
                <Icon name='cog'/>
                <Header.Content>Experience</Header.Content>
            </>

            }
        >

            <>
                {/*chrest*/}
                <SubSection
                    name="chrest"
                    alwaysContent={<>

                        <Header as='h3' icon textAlign='center'>
                            <Header.Content>
                                <Image alt={'CHREST'} as='a' href='https://www.ansys.com'
                                       src={chrestLogo}
                                       size='medium'/><br/>
                                <Header.Subheader>Software Framework Architect, 2020 – Present </Header.Subheader>
                                <Header.Subheader>Buffalo, NY</Header.Subheader>

                            </Header.Content>
                        </Header>
                        <p>
                            Leading development and integration efforts of the Center's shared computational framework <a href="https://ablate.dev">ABLATE - Ablative Boundary Layers At The Exascale</a>
                        </p>
                    </>}
                    sometimesContent={<>
                        <List bulleted>
                            <List.Item>
                                additional responsibilities include:
                                <List.List>
                                    <List.Item>
                                        serving as the Technical Contact for the PSAAP Computer Resource Team
                                    </List.Item>
                                    <List.Item>
                                        helping to mentor undergraduate and graduate students
                                    </List.Item>
                                    <List.Item>
                                        organizing annual reports for the center
                                    </List.Item>
                                </List.List>
                            </List.Item>
                        </List>
                    </>}
                />
                <Divider/>

                {/*Ansys*/}
                <SubSection
                    name="ansys"
                    alwaysContent={<>

                        <Header as='h3' icon textAlign='center'>
                            <Header.Content>
                                <Image alt={'Ansys'} as='a' href='https://www.ansys.com'
                                       src='https://upload.wikimedia.org/wikipedia/commons/e/e5/ANSYS_logo.png'
                                       size='medium'/><br/>
                                <Header.Subheader>Research & Developer Engineer II, 2019 – 2020 </Header.Subheader>
                                <Header.Subheader>Park City, Utah</Header.Subheader>

                            </Header.Content>
                        </Header>
                        <p>
                            Tasked with developing application and supporting framework in-order to expose multiple
                            physics based solvers to Ansys customers for Additive Manufacturing modeling.
                        </p>
                    </>}
                    sometimesContent={<>
                        <List bulleted>
                            <List.Item>
                                contributed to the development and design of solver controller framework
                                <List.List>
                                    <List.Item>
                                        engineered reactive graph framework to link execution and setup of multiple
                                        independent solvers
                                    </List.Item>
                                    <List.Item>
                                        exposed framework through multiple clients: CLI, desktop application, GRPC
                                        server, etc.
                                    </List.Item>
                                </List.List>
                            </List.Item>
                            <List.Item>
                                built and maintained developer operations for the project including: automated
                                regression testing \& build/publish pipelines
                            </List.Item>
                            <List.Item>
                                participated in the maintenance and enhancement of existing web based application for
                                the setup and execution of Additive Manufacturing simulations on distributed platforms
                            </List.Item>
                        </List>
                    </>}
                />
                <Divider/>
                {/*REI*/}
                <SubSection
                    name="rei"
                    alwaysContent={<>

                        <Header as='h3' icon textAlign='center'>
                            <Header.Content>
                                <Image alt={'Reaction Engineering International'} as='a'
                                       href='https://www.reaction-eng.com'
                                       src='https://static1.squarespace.com/static/5b5f93f9c3c16af29b30994e/t/5b621bc2352f53c2985a94ca/1555620982444/?format=1500w'
                                       size='medium'/><br/>
                                <Header.Subheader>Senior Research Engineer, 2013 – 2019 </Header.Subheader>
                                <Header.Subheader>Salt Lake City, Utah </Header.Subheader>

                            </Header.Content>
                        </Header>
                        <p>
                            Took on the role as lead high performance computing research engineer tasked with the
                            development of new frameworks and extension of existing frameworks to HPC applications along
                            with the user interfaces to access the software.
                        </p>
                    </>}
                    sometimesContent={<>
                        <List bulleted>
                            <List.Item>
                                Served as principal investigator and lead engineer on multiple oil & gas related DoE
                                SBIRs exploring the underground reservoir and fracture networks including:
                                <List.List>
                                    <List.Item>
                                        Phase I, HPC-based Validation of Water/Natural Gas Mixtures as a Fracturing
                                        Fluid for Effective Multiple Stage Hydraulic Fracturing. Primary focus of the
                                        project was to make use of advanced modeling and simulation software to perform
                                        assessments of cryogenic natural gas and natural gas-water blends as hydraulic
                                        fracturing fluids.
                                        <List.List>
                                            <List.Item>Involves the extension of an existing HPC framework for
                                                compositional simulations.</List.Item>
                                        </List.List>
                                    </List.Item>
                                    <List.Item>
                                        Phase II, A HPC-based Flowback and Cleanup Simulator Tool for Horizontal Well
                                        Completion and Optimization. The goal of this project is to develop a
                                        comprehensive, high fidelity simulator that integrates fundamental models for
                                        fracturing, the reservoir behavior, and the geomechanical behavior of the
                                        formation.
                                        <List.List>
                                            <List.Item>Primary architect on ground up modeling and simulation
                                                framework</List.Item>
                                            <List.Item>Designed for HPC computation using commercially available HPC
                                                resources</List.Item>
                                            <List.Item>Tasked with developer user interface for remote access of the
                                                software.</List.Item>
                                        </List.List>
                                    </List.Item>
                                </List.List>
                            </List.Item>
                            <List.Item>
                                Principal investigator and one of the lead engineers for an Army SBIR Phase I project,
                                Rapid Prediction of Convective Heat Transfer for Thermal Signature Analysis (Contract
                                Number: W56HZV-14-C-0176)
                                <List.List>
                                    <List.Item>The project involves the development and demonstration of a
                                        next-generation computational fluid dynamics code that utilizes cutting-edge
                                        software and hardware (GPUs) to model convective heat transfer.</List.Item>
                                </List.List>
                            </List.Item>
                            <List.Item>
                                Engineer on a Marine Corp Phase II project that involves time-step-level coupling a
                                sophisticated multi-material, particle-based model (MPMICE) with the LSDYNA finite
                                element code to model blast effects on ground vehicles and occupants.
                            </List.Item>
                            <List.Item>
                                Key engineer on a consulting project to develop a user interface for REI’s
                                three-dimensional reacting two-phase flow model of gases and particles framework.
                            </List.Item>
                            <List.Item>
                                Key engineer contributing to the continued Navy Phase II and II.5 SBIR project, “A
                                Multiscale Modeling and Simulation Framework for Predicting After-Burning Effects from
                                Non-Ideal Explosives”.
                                <List.List>
                                    <List.Item>Implemented adaptive mesh refinement using hybrid multi-threading and MPI
                                        implementation.</List.Item>
                                    <List.Item>Incorporated openMPI Java binding support into framework.</List.Item>
                                    <List.Item>Developed and implemented a novel one-dimensional to three-dimensional
                                        explosive initialization sub-model for FAE explosives, which both reduces
                                        computational time and increases accuracy.</List.Item>
                                </List.List>
                            </List.Item>
                            <List.Item>
                                Served as company Assistant Facility Security Officer (AFSO) and Information Systems
                                Security Officer (ISSO) for select government projects.
                            </List.Item>
                        </List>
                    </>}
                />
                <Divider/>

                {/*UB*/}
                <SubSection
                    name="ub"
                    alwaysContent={<>
                        <Header as='h3' icon textAlign='center'>
                            <Header.Content>
                                <Image alt='University at Buffalo' as='a' href='http://www.buffalo.edu'
                                       src='http://www.buffalo.edu/content/www/brand/resources-tools/downloads/jcr:content/par/image_0.img.280.auto.q65.png/1465414233431.png'
                                       size='small'/><br/>
                                Computational Energy Transport Laboratory
                                <Header.Subheader>Research Assistant, 2008 – 2013 Advisor: <a
                                    href="https://www.cet-lab.org">Dr. Paul DesJardin</a> </Header.Subheader>
                                <Header.Subheader>Buffalo, New York </Header.Subheader>

                            </Header.Content>
                        </Header>
                        <p>
                            Dissertation research involved the computational investigation of flame spread over charring
                            materials. Numerical solution approaches include an Eulerian-Lagrangian moving immersed
                            interface method for simulating burning solids including and finite element model for the
                            thermal response of charring materials in fire environments including:
                        </p>
                    </>}
                    sometimesContent={<>
                        <List bulleted>
                            <List.Item>
                                Contributed to and maintained Computational Energy Transport Lab physics simulation
                                framework
                                <List.List>
                                    <List.Item>
                                        Developed custom implementation of Java based MPI launchers and visual debuggers
                                    </List.Item>
                                </List.List>
                            </List.Item>
                            <List.Item>
                                Developed/implemented Eulerian-Lagrangian moving immersed interface method for
                                simulating burning solids including;
                                <List.List>
                                    <List.Item>level set description of complex moving geometry with novel dynamic
                                        effective resolution Lagrangian mesh update</List.Item>
                                    <List.Item>explicit conservation of energy and mass transfer across the
                                        interface</List.Item>
                                    <List.Item>mesh independent and accurate capture of heat flux to surface</List.Item>
                                </List.List>
                            </List.Item>
                            <List.Item>
                                Developed/implemented finite element model for the thermal response of charring
                                materials in fire environments including
                                <List.List>
                                    <List.Item>study of balsa wood, carbon-epoxy composites, and gypsum
                                        board</List.Item>
                                    <List.Item>volumetric swelling using a novel finite element algorithm </List.Item>
                                    <List.Item>heat, mass, and individual species transport</List.Item>
                                    <List.Item>pyrolysis decomposition</List.Item>
                                </List.List>
                            </List.Item>
                            <List.Item>
                                Extended Finite Element framework to include Streamline Upwind Galerkin Elements (SUPG)
                                for use with highly advecting flows.
                            </List.Item>
                            <List.Item>
                                Developed parallel finite element solver currently in use at the University at Buffalo’s
                                Center for Computational Research supercomputing facilities. </List.Item>
                            <List.Item>
                                Developed unstructured communication model implemented in the finite volume flow solver
                                for the study of complex geometries. </List.Item>
                        </List>
                    </>}
                />
                <Divider/>
                {/*TA*/}
                <SubSection
                    name="ta"
                    alwaysContent={<>
                        <Header as='h3' icon textAlign='center'>
                            <Header.Content>
                                <Image alt='University at Buffalo' as='a' href='http://www.buffalo.edu'
                                       src='http://www.buffalo.edu/content/www/brand/resources-tools/downloads/jcr:content/par/image_0.img.280.auto.q65.png/1465414233431.png'
                                       size='small'/><br/>
                                <Header.Subheader>Teaching Assistant, September 2007 – May 2008</Header.Subheader>
                                <Header.Subheader>Buffalo, New York </Header.Subheader>
                            </Header.Content>
                        </Header>
                        <p>
                            Served as teaching assistant for undergraduate courses in heat transfer and fluid dynamics.
                            Conducted weekly study sessions with an average attendance of about a dozen students.
                        </p>
                    </>

                    }
                />
                <Divider/>
                {/*TA*/}
                <SubSection
                    name="plugPower"
                    alwaysContent={<>
                        <Header as='h3' icon textAlign='center'>
                            <Header.Content>

                                <Image alt='PlugPower' as='a' href='https://www.plugpower.com'
                                       src='https://www.plugpower.com/wp-content/themes/plugpower/public/img/logo-white.svg'
                                       size='small'
                                       style={{backgroundColor: '#074f71', padding: '10px', borderRadius: '5px'}}/><br/>
                                <Header.Subheader>Mechanical Engineering Internship, Summer 2006 and
                                    2007</Header.Subheader>
                                <Header.Subheader>Latham, New York </Header.Subheader>
                            </Header.Content>
                        </Header>
                        <p>
                            Designed GenSys external power conditioning module enclosure and developed factory
                            calibration and functional test specifications for GenGore Fuel Module requiring the design
                            and implementation of the hydrogen fuel module factory test equipment along with the
                            derivation of the associated acceptance criteria.
                        </p>
                    </>

                    }
                />
            </>

        </Section>


    );
}


export default Experience;