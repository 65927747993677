import * as React from "react"
import Section from "../section/Section";
import {Card, Header, Icon, Image, ImageProps, List} from "semantic-ui-react";

import SlideCarousel from "../slideCarousel/SlideCarousel";



import loginPic from "../../assets/currentProject/login.png";
import dashBoardPic from "../../assets/currentProject/dashBoard.png";
import achievementsPic from "../../assets/currentProject/achievements.png";
import appStatusPic from "../../assets/currentProject/appStatus.png";
import eventSignupPic from "../../assets/currentProject/eventSignup.png";
import newsAndUpdatesPic from "../../assets/currentProject/newsAndUpdates.png";
import infoAndLearnPic from "../../assets/currentProject/infoAndLearn.png";
import pastFostersPic from "../../assets/currentProject/pastFosters.png";
import courseWorkPic from "../../assets/currentProject/courseWork.png";
import cawsHubPic from "../../assets/currentProject/cawsHub.png";
import cawsAddOnPic from "../../assets/currentProject/cawsAddOn.png";



type Props = {
}

const height = 400;

const CurrentProject: React.FunctionComponent<Props> = (props) => {
    return (
        <>
        <Section
            name="currentProject"
            header={
            <>
                <Icon name='file code'/>
                <Header.Content >Current Project</Header.Content>
            </>
        }>

            <p> Serving in both a volunteer and leadership role within CAWS, the need for a central location for communication was needed.
                To address this need The CAWS Den was developed.  The primary objectives behind the development of The CAWS Den were
            </p>
            <List bulleted>
                <List.Item>Provide fosterers and volunteers an easy resource for information thereby reducing work for head volunteers in leadership positions.</List.Item>
                <List.Item>
                    Integrate into existing tools including familiar to the head volunteers:
                    <List.List>
                        <List.Item>Google Drive & G-Suite</List.Item>
                        <List.Item>Shelter Manger (Existing Animal Database)</List.Item>
                        <List.Item>FaceBook</List.Item>
                    </List.List>
                </List.Item>
                <List.Item>Reduce head volunteers time-commitment using streamed lined practices and automation.</List.Item>
            </List>
            <p>
                The CAWS Den is composed of a single page web application built using React-Redux and a custom Go server responsible for merging local and remote data from multiple resources.
            </p>
        </Section>

        <SlideCarousel
            height={height}
            cards={
                [
                    <Card  style={{height:height}}>
                        <Image src={dashBoardPic} wrapped ui={false} />
                        <Card.Content>
                            The dashboard provides access to up-coming adoption events and the CAWS Feed.  The feed is a combination of news articles, Instagram post, and achievement awards.                        </Card.Content>
                    </Card>,
                    <Card style={{height:height}}>
                        <Image src={loginPic} wrapped ui={false} />
                        <Card.Content>
                            CAWS fosterers have a wide variety of technical skill sets so ensure that they could login using familiar tools (Google & FaceBook) was critical for adoption.
                        </Card.Content>
                    </Card>,
                    <Card style={{height:height}}>
                        <Image src={achievementsPic} wrapped ui={false} />
                        <Card.Content>
                            A custom automatic and manual achievement system encourages fosterers and volunteers to keep going while showing what they already accomplished.
                        </Card.Content>
                    </Card>,
                    <Card style={{height:height}}>
                        <Card.Content>
                            <Card.Header>Custom Go Server</Card.Header>
                            <p>
                                The custom written go server handles user, animal, achievement, events, etc. while combine data from multiple sources. Key features include:
                            </p>
                            <List bulleted>
                                <List.Item>automatic permission based access</List.Item>
                                <List.Item>automatic achievement assignment and notification</List.Item>
                                <List.Item>dynamic caching from Google Drive CMS</List.Item>
                            </List>
                        </Card.Content>

                    </Card>,
                    <Card style={{height:height}}>
                        <Image src={appStatusPic} wrapped ui={false} />
                        <Card.Content>
                            The Application Status page walks new volunteers and fosters through the steps need to start volunteering.
                        </Card.Content>
                    </Card>,
                    <Card style={{height:height}}>
                        <Image src={eventSignupPic} wrapped ui={false} />
                        <Card.Content>
                            Events available to volunteers and fosters dynamically appear where they can sign-up or register their fosters.  The registration instantly syncs with Google Sheets available to the head volunteers.                        </Card.Content>
                    </Card>,
                    <Card style={{height:height}}>
                        <Image src={newsAndUpdatesPic} wrapped ui={false} />
                        <Card.Content>
                            Head volunteers can write News & Update articles using their editor of choice (Google Docs) where they automatically appear here, the feed, and emailed to volunteers according to use preference.
                        </Card.Content>
                    </Card>,
                    <Card style={{height:height}}>
                        <Image src={infoAndLearnPic} wrapped ui={false} />
                        <Card.Content>
                            Volunteers in leadership positions can create reference documentation using Google Docs that present to volunteers in the same folder hierarchy as embedded html.  This systems reduces time for the volunteers to answer questions or write documentation.
                        </Card.Content>
                    </Card>,
                    <Card style={{height:height}}>
                        <Image src={pastFostersPic} wrapped ui={false} />
                        <Card.Content>
                            Fosterers can view the status and pictures of previous fosters or view details of current fosters.  Answering questions about their current fosters get them ready for adoption.
                        </Card.Content>
                    </Card>,
                    <Card style={{height:height}}>
                        <Image src={courseWorkPic} wrapped ui={false} />
                        <Card.Content>
                            Fosterers and volunteers looking to learn can utilize the custom course framework to watch lessons and videos that help them help the animals.
                        </Card.Content>
                    </Card>,
                    <Card style={{height:height}}>
                        <Image src={cawsHubPic} wrapped ui={false} />
                        <Card.Content>
                            As volunteers for CAWS you can gain access to the CAWS Hub, a central location for supplies and donations to help you fosterer.  The Den provides the required unique key to gain access and records each entry.
                        </Card.Content>
                    </Card>,
                    <Card style={{height:height}}>
                        <Image centered src={cawsAddOnPic} size='tiny'/>
                        <Card.Content>
                            Custom Google gMail and Sheet Add-Ons provide quick access actions to head volunteers reducing repetitive tasks and response times.
                        </Card.Content>
                    </Card>







                ]
            }

        />



        </>


    );
}


export default CurrentProject;