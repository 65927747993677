import * as React from "react"
import Section from "../section/Section";
import {Header, Icon} from "semantic-ui-react";

type Props = {
}
const About: React.FunctionComponent<Props> = (props) => {
    return (
        <Section
            name="about"
            header={
            <>
                <Icon name='user'/>
                <Header.Content > About</Header.Content>
            </>
        }>
            <p>
                Matt has focused his career upon the development of complex simulation frameworks and required interfaces to allow these powerful tools to be used by a wide variety of people. Holding his PhD in Mechanical Engineering (2013) from the University at Buffalo, he is well versed in the mathematics, physics, and software programming methods associated with CFD and FE based formulations. He is experienced in developing models using a wide variety of frameworks and languages including full web stack, modern software methods, and developing new computational methods/tools.
            </p>
            <p>
                It was during his tenure at the University at Buffalo that Matt first developed his desire to help animals.  After rescuing his first dog Tucker, Matt served as a founding board member of the NonProfit Friends of Ellicott that was chartered to established a local dog park. After moving to Salt Lake City he found himself volunteering with Community Animal Welfare Society, eventually joining the Board of Directors and becoming president.  Matt's skill sets have overlapped in both nonprofit and professional pursuits as he developed software, policies, and procedures to present complex tasks and tools to people.
            </p>


        </Section>


    );
}


export default About;