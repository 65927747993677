import React, {Component, RefObject} from 'react';
import {Grid, Image, Transition, Visibility} from "semantic-ui-react";
import './SlideCarousel.css';



interface SlideWrapperProps {
    screenWidth:number;
    children:any;
    class:string;
    activate?:() => any;
}


class SlideWrapper extends Component<SlideWrapperProps> {
    state = {componentWidth:0};

    render() {

        //Compute the

        return(
            <div
                className={this.props.class}
                style={{maxWidth:this.props.screenWidth*.8}}
            >
                <div
                    style={{display: "inline-block"}}
                    onClick={this.props.activate}

                >
                    {this.props.children}
                </div>

            </div>
        );



    }
}

export default SlideWrapper;
