import React, { Component } from 'react';
import {Container,Icon,Header,Divider} from "semantic-ui-react";
import './FloatingWrapper.css';

//Define the expected props
interface Props  {
    //Define the props we expect
    children?:any;

    //Store the offset
    offset:number;

    //Set how much to over lap the content
    overlap:number;
}

class FloatingWrapper extends Component<Props> {
    render() {
        return (
            <div
                className="FloatingWrapper"
                style={
                    {
                        marginTop:this.props.offset
                    }
                }
            >
                <Container style={
                    {
                        top:-1.0*this.props.overlap,
                        position: "relative",

                }
                }>
                    {this.props.children}

                </Container>


            </div>
        );
    }
}

export default FloatingWrapper;
