import * as React from "react"
import Section from "../section/Section";
import {Divider, Header, Icon, Image, List, Segment} from "semantic-ui-react";
import SubSection from "../section/SubSection";
import cawsLogo from "../../assets/cawsLogo.png";

type Props = {
}
const NonProfitLeadership: React.FunctionComponent<Props> = (props) => {
    return (
        <Section
            name={"nonprofitLeadership"}
            header={
            <>
                <Icon name='globe'/>
                <Header.Content>NonProfit Leadership</Header.Content>
            </>

            }
        >

            <>
                {/*CAWS Board*/}
                <SubSection
                    name="caws"
                    alwaysContent={
                        <>
                            <Header as='h3' textAlign='center'>
                                <Header.Content >
                                    <Image alt={'CAWS - Community Animal Welfare Society'} as='a' href='https://caws.org' src={cawsLogo} size='medium' /><br/>
                                    <Header.Subheader>Salt Lake City, Utah</Header.Subheader>
                                </Header.Content>
                            </Header>
                            <p >
                                CAWS is a no-kill, volunteer and foster-based 501(c)(3) nonprofit organization whose mission is working with the community to help animals. As Utah’s oldest animal rescue group, our ongoing rescue, foster, education and adoption programs help animals whose time is up at shelters, those who are abandoned, and as our resources allow, those whom their owners can no longer keep.
                            </p>
                            <Header as='h3' textAlign='center'>
                                <Header.Content >
                                    {'Board President \u2022 Board Secretary \u2022 Dog Adoption Coordinator'}
                                </Header.Content>
                            </Header>

                        </>
                    }
                    sometimesContent={
                        <>
                            <Header as='h4'>
                                Board President
                                <Header.Subheader> 2019  – 2020 </Header.Subheader>
                            </Header>
                            <List bulleted>
                                <List.Item>
                                    Implement policies and procedures to ensure governing accountability and transparency
                                </List.Item>
                                <List.Item>
                                    Provide the tools and resources to engage with and support new and long term fosters
                                </List.Item>
                                <List.Item>
                                    Establish the CAWS Hub, a central location for foster supplies, donations, meet & greets, and other resources thereby reducing the entry to fostering.
                                </List.Item>
                            </List>
                            <Header as='h4'>
                                Board Secretary
                                <Header.Subheader> 2017  – 2019 </Header.Subheader>
                            </Header>
                            <p>
                                As a volunteer based organization, CAWS's most valuable resource is volunteers. During the time as secretary, I focused upon providing policy and technological tools to volunteers including:
                            </p>
                            <List bulleted>
                                <List.Item>
                                    developed on-line course work and system for training new foster applicants
                                </List.Item>
                                <List.Item>
                                    automated adopter follow-up system after one-month and six-months
                                </List.Item>
                                <List.Item>
                                    developed Google gMail/sheets Add-On that integrates with custom adopter/animal database
                                </List.Item>
                                <List.Item>
                                    automated animal kennel card creation replacing Word based template
                                </List.Item>
                            </List>
                            <Header as='h4'>
                                Dog Adoption Coordinator
                                <Header.Subheader> 2016  – 2018 </Header.Subheader>
                            </Header>
                            <List bulleted>
                                <List.Item>
                                    Routine responsibilities include running dog adoption events, coordinating rescue activities, and interacting with fosters & adopters
                                </List.Item>
                                <List.Item>
                                    Developed and implemented automated email follow up system for recent dog adoptions                                </List.Item>
                                <List.Item>
                                    Built foster information website using tools available to the organization
                                </List.Item>
                            </List>
                        </>
                    }

                />
                <Divider/>

                {/*UB*/}
                <SubSection
                    name="friendsOfEllicott"
                    alwaysContent={
                        <>
                            <Header as='h3' icon textAlign='center'>
                                <Header.Content >
                                    <a href="https://friendsofellicott.com/">Friends of Ellicott Island 501(c)(3)</a>
                                    <Header.Subheader>Founding Board Member, Secretary 2013-2014 </Header.Subheader>
                                    <Header.Subheader>Amherst, New York </Header.Subheader>

                                </Header.Content>
                            </Header>
                            <p >
                                The organization was founded to work with Erie County Park office to establish an off-leash dog park within the county parks.  The organization is responsible for fundraising, dog park rules/regulations, improvement projects, liability insurance, and community outreach.
                            </p>
                            <List bulleted>
                                <List.Item>
                                    Responsible for website development, maintained, and other IT concerns
                                </List.Item>
                                <List.Item>
                                    Developed and implemented membership program
                                </List.Item>
                                <List.Item>
                                    Contributed to community outreach and fundraising
                                </List.Item>
                            </List>
                        </>
                    }
                />

            </>

        </Section>


    );
}


export default NonProfitLeadership;