import React, {Component, RefObject} from 'react';
import {Grid, Icon, Image, Responsive, Transition, Visibility} from "semantic-ui-react";
import './SlideCarousel.css';
import SlideWrapper from "./SlideWrapper";
import {ResponsiveOnUpdateData} from "semantic-ui-react/dist/commonjs/addons/Responsive";
import {useSwipeable, Swipeable, EventData} from 'react-swipeable'


interface SlideCarouselProps {
    cards:any[];
    height:number;

}

interface SlideCarouselState {
    activeIndex:number;
    compWidth:number;
    compHeight:number;
}

class SlideCarousel extends Component<SlideCarouselProps,SlideCarouselState> {
    state = {activeIndex:0, compWidth:0, compHeight:0}



    windowChange = (event: React.SyntheticEvent<any>, data: ResponsiveOnUpdateData)=>{
            this.setState({compWidth:data.width})

    }


    handleSwipe = (event: EventData) => {
       if(event.dir == 'Left'){
           this.moveLeft();
       }else if (event.dir == 'Right'){
           this.moveRight();
       }

    }
    handleKeyPress = (event: EventData) => {
        if(event.dir == 'Left'){
            this.moveLeft();
        }else if (event.dir == 'Right'){
            this.moveRight();
        }

    }

    moveLeft = () =>{
        if(this.state.activeIndex < this.props.cards.length-1){
            this.setState({activeIndex:this.state.activeIndex+1})
        }
    }

    moveRight =() =>{
        if(this.state.activeIndex >0){
            this.setState({activeIndex:this.state.activeIndex-1})
        }
    }



    render() {

        //Store the swipe config
        const swipeConfig = {
            delta: 10,                             // min distance(px) before a swipe starts
            preventDefaultTouchmoveEvent: false,   // preventDefault on touchmove, *See Details*
            trackTouch: true,                      // track touch input
            trackMouse: true,                     // track mouse input
            rotationAngle: 0,                      // set a rotation angle
        }


        //Check to see if need to add another class
        let mobileClass = "";
        if(Responsive.onlyTablet.minWidth && this.state.compWidth < Responsive.onlyTablet.minWidth ) {
            mobileClass = "Mobile" ;
        }

        return (

            <Swipeable
                onSwiped={this.handleSwipe}
                {...swipeConfig} >
                <Responsive
                    className="SlideCarousel"
                    onUpdate={this.windowChange}
                    fireOnMount
                    style={{height:this.props.height}}
                >
                    {
                        this.props.cards.map(
                    (props: any, index: number) => {

                        //Select the style
                        let classStyle = "Slide SlideSelected";
                        if (index < this.state.activeIndex-1) {
                            classStyle="Slide SlideFarLeft"+mobileClass;
                        }else if (index < this.state.activeIndex) {
                            classStyle="Slide SlideNextLeft"+mobileClass;
                        } else if (index > this.state.activeIndex+1) {
                            classStyle="Slide SlideFarRight"+mobileClass;
                        }else if (index > this.state.activeIndex) {
                            classStyle = "Slide SlideNextRight"+mobileClass;
                        }

                        return (
                            <SlideWrapper
                                key={index}
                                class={classStyle}
                                screenWidth={this.state.compWidth}
                                activate={this.state.activeIndex != index? () => this.setState({activeIndex:index}): undefined}

                            >
                                {props}
                            </SlideWrapper>
                        );
                    })
                    }
                </Responsive>
                {/*  Center the select options  */}
                <div className="SlideNavBar"
                >
                    {
                        this.props.cards.map(
                            (props: any, index: number) => {
                                return <Icon key={index}
                                    name={(this.state.activeIndex==index?"circle":"circle outline")}
                                    onClick={() => this.setState({activeIndex:index})}
                                />
                            }
                        )
                    }
                </div>
            </Swipeable>

        )

    }
}

export default SlideCarousel;
