import React, { Component } from 'react';
import background from "../../assets/main_background-min.jpg";
import {Container, Grid, Header, Icon, Message, Responsive, Segment} from "semantic-ui-react";
import {ResponsiveOnUpdateData} from "semantic-ui-react/dist/commonjs/addons/Responsive";
import './FixedHeader.css';



interface Props {

}
interface HeaderState {
    backgroundPos:number;
}

class FixedHeader extends Component<Props, HeaderState> {
    state={backgroundPos:0}

    updateHeader= (event: React.SyntheticEvent<HTMLElement>, data: ResponsiveOnUpdateData) => {

        //Store the max and min pos
        const minPos = 45;
        const maxPos =100;

        const minScreen = 500;
        const maxScreen = 1000

        //update the pos
        let newPos = (minPos + (data.width -minScreen)*(maxPos-minPos)/(maxScreen-minPos));
        console.log(newPos);
        newPos=Math.max(newPos, minPos);
        newPos=Math.min(newPos, maxPos);

        // this.setState({backgroundPos:newPos});


        //Compute the pos
        // const pos = backgroundPos

    }

    render() {
        return (
                <Grid
                    verticalAlign='middle'
                    columns={1}
                    centered
                    style={

                    {
                        backgroundImage:  `url(${background})`,
                        position:"fixed",
                        top:0,
                        left:0,
                        z_index: -99,
                        width:"105%",
                        height:400,
                        backgroundPosition: `left ${this.state.backgroundPos.toFixed(0)}% top 000% `
                    }
                }>

                    <Grid.Row>
                        { `` }
                        <Grid.Column>
                            <Responsive
                                {...Responsive.onlyMobile}
                                className='HeaderBox'
                                as={Header}
                                textAlign='center'
                                inverted
                                style={
                                    {
                                        fontSize:"2.5em",
                                        width:'60%',
                                        minWidth:'200px',
                                        margin: "auto",
                                        padding:'20px',
                                        borderRadius:'5px',
                                        marginTop:"-40px"
                                    }
                                }
                            >
                                {/*<Icon name='grid layout' />*/}
                                Matthew McGurn
                                <Header.Subheader>
                                    {'Engineer \u2022 Developer \u2022 Volunteer'}
                                </Header.Subheader>
                            </Responsive>
                            <Responsive
                                as={Header}
                                textAlign='center'
                                inverted
                                className='HeaderBox'
                                style={
                                    {
                                        fontSize:"3.5em",
                                        width:'60%',
                                        minWidth:'200px',
                                        margin: "auto",
                                        padding:'20px',
                                        borderRadius:'5px',
                                        marginTop:"-40px"
                                    }
                                }
                                minWidth={Responsive.onlyTablet.minWidth}
                            >
                                {/*<Icon name='grid layout' />*/}
                                Matthew McGurn
                                <Header.Subheader>
                                    {'Engineer \u2022 Developer \u2022 Volunteer'}
                                </Header.Subheader>
                            </Responsive>



                        </Grid.Column>
                    </Grid.Row>
                </Grid>
        );
    }
}

export default FixedHeader;
