import * as React from "react"
import Section from "../section/Section";
import {Header, Icon, List, Image, Responsive} from "semantic-ui-react";
import SubSection from "../section/SubSection";
import FosterList from "./FosterList";

type Props = {
}

//Store the content
const content = (
    <p>
        I first started with CAWS as a foster and volunteer.  What motivates me and keeps me helping is the difference in
        lives (both human and dog) that fostering can make.  Each of these dogs has now found their their forever home.
    </p>
);

const Fostering: React.FunctionComponent<Props> = (props) => {
    return (
        <Section
            name="fostering"
            header={
            <>
                <Icon name='paw'/>
                <Header.Content >Fostering & Volunteering</Header.Content>

            </>
        }>

            {/*Only use the subsection was smaller than tablet*/}
            <Responsive
                as={SubSection}
                maxWidth={Responsive.onlyTablet.maxWidth}
                alwaysContent={
                    content
                }
                name={"fosterList"}
                sometimesContent={<FosterList popUp={false} imgSize={100}/>}
                sometimesContentClosedHeight={100}
            />
            <Responsive
                minWidth={Responsive.onlyTablet.maxWidth}
            >
                {content}
                <FosterList popUp={true} imgSize={100}/>

            </Responsive>

        </Section>


    );
}


export default Fostering;