import React, {Component, RefObject} from 'react';
import {
    Container,
    Grid,
    Header,
    Icon,
    Responsive,
    Segment,
    SegmentGroup,
    Transition,
    TransitionGroup
} from "semantic-ui-react";
import './SubSection.css';

//Define the expected props
interface Props  {
    //Show the stuff always shown
    alwaysContent:any;

    //show the rest of the content
    sometimesContent?:any;

    //Store the name for quick linking
    name:string;

    //Store if this is default open
    defaultOpen?:boolean;

    //Store the sometimes content height
    sometimesContentClosedHeight?:number;
}

//Store the state
interface SubSectionState {
    expanded:boolean;
    sectionHeight:number;
}

class SubSection extends Component<Props,SubSectionState> {
    state = {expanded:this.props.defaultOpen==true, sectionHeight:100};

    //Store the totalRef and collapseRef
    sectionRef:RefObject<HTMLDivElement>;


    constructor(props:Props) {
        super(props);
        this.sectionRef = React.createRef<HTMLDivElement>();
    }

    //Update the showhide
    showHide = () => {
        this.setState({
            expanded:!this.state.expanded
        });
    }

    updateSizes = () =>{
        //Get the two sizes
        const fullSize = this.sectionRef.current?this.sectionRef.current.scrollHeight:0;

        //Now set them
        this.setState({
            sectionHeight:fullSize,
        })

    }

    render() {

        //Get the closed height
        let closedHeight = 0;
        if(this.props.sometimesContentClosedHeight){
            closedHeight= this.props.sometimesContentClosedHeight;
        }

        return (
            <Responsive
                className={"ExpandingSubSection"}
                as={Segment}
                vertical
                id={this.props.name}
                fireOnMount
                onUpdate={this.updateSizes}
            >{/* Set the height depending if it is expanded or now   */}
                {/*Just show the section as normal*/}
                {this.props.alwaysContent}

                {/*Wrap the expanding section in it's own div*/}
                <div
                    className="ExpandingSubSection"
                    ref={this.sectionRef}
                    style={{height:this.state.expanded?this.state.sectionHeight:closedHeight}}
                >


                    {/*Show the some times content*/}
                    {this.props.sometimesContent}

                </div>

                {/* Show the expand shrink if only there is sometimes content   */}
                {this.props.sometimesContent &&
                    <div className={"ShowControlGroup"} onClick={this.showHide}>
                        <Icon className={this.state.expanded ? "ShowLessIcon" : "ShowMoreIcon"} name='chevron down'
                              size='big'/>
                    </div>
                }
            </Responsive>


    );
    }
}

export default SubSection;
