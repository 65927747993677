import React, { Component } from 'react';
import {Container, Divider, Header, Segment} from "semantic-ui-react";

//Define the expected props
interface Props  {
    //Define the props we expect
    header?:any;

    //Add in any child content
    children?:any;

    //Store the name for quick linking
    name:string;
}

class Section extends Component<Props> {
    render() {
        return (
            <>
                {/*Output a header if specified*/}
                {this.props.header &&
                    <>
                    <Header as='h2' attached='top' id={this.props.name}>
                        {this.props.header}
                    </Header>
                    </>
                }
                <Segment attached={this.props.header != undefined}>
                    {this.props.children}
                </Segment>

            </>
        );
    }
}

export default Section;
