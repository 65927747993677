import React, { Component } from 'react';
import {Container, Grid, Header, List, Segment,Image} from "semantic-ui-react";
import logo from "../../assets/tuckerLogo-min.png";

class Footer extends Component {


    render() {
        return (
            <Segment inverted vertical style={{ padding: '5em 0em', zIndex:600 }}>
                <Container>
                    <Grid divided inverted stackable>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Header inverted as='h4' content='Contact' />
                                <List link inverted>
                                    <List.Item as='a' href='mailto:matt@mcgurn.dev'>
                                        <List.Icon name='mail' />
                                        <List.Content >
                                            <List.Header>Matt@McGurn.dev</List.Header>
                                            <List.Description>for development and engineering</List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item as='a' href='mailto:matt@rescueden.org'>
                                        <List.Icon name='mail' />
                                        <List.Content >
                                            <List.Header>Matt@rescueden.org</List.Header>
                                            <List.Description>for nonprofit and rescue</List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item as='a' href='https://www.linkedin.com/in/matt-mcgurn/'>
                                        <List.Icon name='linkedin' />
                                        <List.Content >
                                            <List.Header>LinkedIn Profile</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item as='a' href={process.env.PUBLIC_URL + "/files/McGurnResume.pdf"} download>
                                        <List.Icon name='download' />
                                        <List.Content >
                                            <List.Header>Download Résumé</List.Header>
                                        </List.Content>
                                    </List.Item>


                                </List>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Header as='h4' inverted>
                                    Helping
                                </Header>
                                <p>
                                    If you are interested in learning more about animal rescue and how you can contribute please
                                    contact <a href='mailto:mattm@caws.org'>me</a>, visit <a href="https://caws.org/">caws.org</a>, or your local animal rescue or shelter.
                                </p>
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Image centered src={logo} fluid size='tiny' />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
        );
    }
}

export default Footer;
