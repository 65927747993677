import * as React from "react"
import {List, Popup} from "semantic-ui-react";
const pubListJson = require('../../assets/pubs.json');

type Props = {
}

function getRef(item:any): any{
    return (
        <List.Item key={JSON.stringify(item)+"item"}>
            <List.Icon name='circle' />
            <List.Content>
                {item.link &&
                <List.Header as='a' href={item.link}>{item.title}</List.Header>
                }
                {!item.link &&
                <List.Header>{item.title}</List.Header>
                }
                <List.Description>
                    {item.other}
                    {item.year &&
                    " (" + item.year +")"
                    }
                </List.Description>
            </List.Content>
        </List.Item>
    );
}

const Publications: React.FunctionComponent<Props> = (props) => {

    return (
        <List key={"pubList"} items={
            pubListJson.map( (item:any) =>{

                //If there is an abstract
                if(item.abstract){
                    return <Popup mouseEnterDelay={1500} key={JSON.stringify(item)} trigger={getRef(item)} content={item.abstract} wide='very'/>
                }else{
                    return getRef(item);
                }

            })
        } />
    );
}


export default Publications;