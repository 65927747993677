import React, {Component} from 'react';
import FixedHeader from "./header/FixedHeader";
import FloatingWrapper from "./floatingwrapper/FloatingWrapper";
import About from "./content/About";
import Education from "./content/Education";
import Experience from "./content/Experience";
import NonProfitLeadership from "./content/NonProfitLeadership";
import Menu from "./Menu";
import Fostering from "./fostering/Fostering";
import RelatedWork from "./content/RelatedWork";
import {Visibility, VisibilityEventData} from "semantic-ui-react";
import Footer from "./footer/Footer";
import CurrentProject from "./content/CurrentProject";


interface AppProps {

}

interface AppState {
    headerShown: boolean;
}

class App extends Component<AppProps, AppState> {
    state = {headerShown: false};


    headerUpdate = (nothing: null, data: VisibilityEventData) =>{
        this.setState({headerShown:data.calculations.topVisible});
    }

    render() {
        return (
            <>
                <Menu inverted={false}/>
                <FixedHeader/>
                <Visibility
                    fireOnMount
                    onUpdate={this.headerUpdate}
                    style={{overflowX:"hidden"}}
                    // onTopPassed={this.headerUpdate}
                    // onTopPassedReverse={this.headerUpdate}
                >
                    <FloatingWrapper offset={320} overlap={20}>

                        <About/>
                        <Experience/>
                        <Education/>
                        <RelatedWork/>
                        <NonProfitLeadership/>
                        <CurrentProject/>
                        <Fostering/>
                    </FloatingWrapper>
                    <Footer/>
                </Visibility>


            </>
        );
    }
}

export default App;
