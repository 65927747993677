import * as React from "react"
import {Component} from "react";
import {Card, Grid, Header, Image, Modal, Popup} from "semantic-ui-react";

//Define the expected props
interface Props {
    //Show the stuff always shown
    aniInfo: any;
    imgSize: number;
    popUp: boolean;

}

//Store the state
interface SubSectionState {
    modalOpen: boolean;
}

class FosterItem extends Component<Props, SubSectionState> {
    state = {modalOpen: false};

    handleOpen = () => this.setState({modalOpen: true})

    handleClose = () => this.setState({modalOpen: false})

    /**
     * Get the list item
     */
    getListItem = () => {
        return (//Fix the size of the columns
            <Grid.Column
                style={{
                    width: this.props.imgSize, height: this.props.imgSize,
                }}
                onClick={this.handleOpen}
            >
                {/*Make an image cropper*/}
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: "50%",
                        padding: "0px"
                    }}
                >
                    {/*Center the image*/}
                    <Image
                        style={{
                            display: "inline", margin: "0 auto", minWidth: "100%", minHeight: "100%",
                        }}
                        src={this.props.aniInfo.thumbnailUrl}
                    />
                </div>
            </Grid.Column>);
    }

    /**
     * Get the modal pop - up
     */
    getPopUp = () => {
        return (<>
                <Image centered size='small' src={this.props.aniInfo.imgUrls[0]}/>
                <Card.Content>
                    <Card.Header>{this.props.aniInfo.name}</Card.Header>
                    <Card.Meta>
                        {this.props.aniInfo.color} {this.props.aniInfo.breed}<br/>
                        {this.props.aniInfo.age}
                    </Card.Meta>
                </Card.Content>
            </>);
    }

    /**
     * Get the modal pop-up
     */
    getModal = () => {
        return (<Modal
                open={this.state.modalOpen}
                onClose={this.handleClose}
                key={"modal" + this.props.aniInfo.id}
            >
                <Modal.Header>{this.props.aniInfo.name}</Modal.Header>
                <Modal.Content image scrolling>
                    <Image wrapped size='huge' src={this.props.aniInfo.imgUrls[0]}/>
                    <Modal.Description>
                        <Header>{this.props.aniInfo.color} {this.props.aniInfo.breed}<br/>
                            {this.props.aniInfo.age}
                        </Header>
                        <p>{this.props.aniInfo.bio}</p>
                    </Modal.Description>
                </Modal.Content>
            </Modal>);

    }

    render() {
        //Include a pop up if needed
        if (this.props.popUp) {
            return (<>
                    {this.getModal()}

                    {/*Show the real popup*/}
                    <Popup
                        key={this.props.aniInfo.ID}
                        trigger={this.getListItem()}

                        as={Card}
                        content={this.getPopUp()}
                        wide='very'
                    />

                </>

            );
        } else {
            //Else there is no pop-up
            return (<>
                    {this.getModal()}
                    {this.getListItem()}
                </>

            );
        }
    }

}


export default FosterItem;